<template>
    <painel titulo="Eventos - Configuração de Atendimento" icone="building" :refreshFunction="atualizar">
        <erros-box :erros="erros"></erros-box>
        <div class="formgrid grid p-fluid">
            <div class="field col-12 md:col-4">
                <label class="required">Descrição Atendimento</label>
                <InputText type="text" v-model="nomeRealizacao" @input="v$.nomeRealizacao.$touch()" maxlength="180" />
                <small class="p-error" v-if="v$.nomeRealizacao.$error">O nome da realização é obrigatório</small>
            </div>
            <div class="field col-12 lg:col-4">
                <label class="required">Projeto</label>
                <Dropdown v-model="codProjeto" :options="projetos" optionLabel="nomePratif" optionValue="codPratif"
                    :filter="true" placeholder="Selecione o projeto" :showClear="true" :filterFields="['nomePratif']"
                    v-if="projetos"></Dropdown>
            </div>
            <div class="field col-12 lg:col-4">
                <label class="required">Ação</label>
                <Dropdown v-model="codAcao" :options="acoesProjeto" optionLabel="nomeAcao" optionValue="codAcao"
                    :filter="true" placeholder="Selecione a ação" :showClear="true" :filterFields="['nomeAcao']"
                    v-if="acoesProjeto"></Dropdown>
            </div>
            <div class="field col-12 md:col-4">
                <label class="required">Instrumento</label>
                <Dropdown v-model="codInstrumento" :options="instrumentos" optionLabel="descCategoria"
                    optionValue="codCategoria" :filter="true" placeholder="Selecione o instrumento" :showClear="true"
                    :filterFields="['descCategoria']" v-if="instrumentos"></Dropdown>
            </div>
            <div class="field col-12 lg:col-4">
                <label class="required">Canal Atedinento</label>
                <Dropdown v-model="codCanalAtendimento" :options="canaisAtendimentos" optionLabel="descMeioAtendimento"
                    optionValue="codMeioAtendimento" :filter="true" placeholder="Selecione a ação" :showClear="true"
                    :filterFields="['nomeAcao']" v-if="canaisAtendimentos"></Dropdown>
            </div>
            <div class="field col-12 md:col-4">
                <label class="required">Área Tema</label>
                <Dropdown v-model="codAreaAtendimento" :options="temas" optionLabel="temaSuperiorAreaTematica"
                    optionValue="codAreaTematica" :filter="true" placeholder="Selecione a Área Tema" :showClear="true"
                    :filterFields="['temaSuperiorAreaTematica']" v-if="temas"></Dropdown>
                <small class="p-error" v-if="v$.codAreaAtendimento.$error">a área de atendimento é obrigatória</small>
            </div>
            <div class="field col-12 md:col-2">
                <label class="required">Tipo da Realização</label>
                <InputText type="text" v-model="tipoRealizacao" @input="v$.tipoRealizacao.$touch()" maxlength="10" />
                <small class="p-error" v-if="v$.tipoRealizacao.$error">O tipo da realização é obrigatório</small>
            </div>
            <div class="field col-12 md:col-2">
                <label class="required">Carga Horária</label>
                <InputNumber v-model="cargaHoraria" suffix=" minutos" @input="v$.cargaHoraria.$touch()" />
                <small class="p-error" v-if="v$.cargaHoraria.$error">A carga horária é obrigatória</small>
            </div>
            <div class="field col-12 lg:col-8">
                <label>Unidade Organizacional</label>
                <Dropdown v-model="codigoUnidadeOrganizacional" :options="unidadesOrganizacionais"
                    optionLabel="descricao" optionValue="codigoUnidadeOrganizacional" :filter="true"
                    placeholder="Selecione a unidade organizacional" :showClear="true"
                    :filterFields="['codigoUnidadeOrganizacional', 'descricao']" v-if="unidadesOrganizacionais">
                </Dropdown>
            </div>
            <div class="field col-12 md:col-3 lg:col-3">
                <label>CPF Responsável</label>
                <div class="p-inputgroup">
                    <InputMask v-model="cpfResponsavel" mask="999.999.999-99" :unmask="true" :disabled="cpfPesquisado"
                        @keypress.enter="pesquisarCpf()" />
                    <Button v-if="!cpfPesquisado" icon="pi pi-search" @click="pesquisarCpf()"
                        title="Clique para pesquisar o CPF" />
                    <Button v-if="cpfPesquisado" icon="pi pi-undo" @click="informarNovoCpf()"
                        title="Clique para informar um novo CPF" />
                </div>
            </div>
            <div class="field col-12 md:col-3 lg:col-9">
                <label>Responsável</label>
                <InputText type="text" v-model="responsavel" :disabled="true" />
            </div>
            <div class="field col-12">
                <label>Orientação ao Cliente</label>
                <Textarea v-model="orientacaoCliente" rows="3" maxlength="5000" />
            </div>
        </div>
        <slot name="botoes"></slot>
        <Button label="Cancelar" icon="pi pi-times-circle" class="p-button-secondary" @click="cancelar" />
        <Button label="Salvar" icon="pi pi-check-circle" class="ml-2 p-button-primary" @click="confirmarSalvar()"
            :disabled="v$.$invalid" />
    </painel>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import AtendimentoDetalheServices from './services';

export default {
    setup() {
        return { v$: useVuelidate() };
    },

    props: {
        operacao: {
            type: String,
        },

        atendimentoDetalhe: {
            type: Object,
        },

        erros: {
            type: Array,
        },

        cancelar: {
            type: Function,
        },
    },

    emits: ['salvar', 'atualizar', 'pesquisarCpf'],

    data() {
        return {
            nomeRealizacao: null,
            projetos: [],
            codProjeto: null,
            acoes: [],
            codAcao: null,
            acaoInicial: true,

            codInstrumento: null,
            instrumentos: [],
            canaisAtendimentos: [],
            codCanalAtendimento: null,
            temas: [],
            codAreaAtendimento: null,
            temaSuperiorAreaTematica: null,

            tipoRealizacao: null,
            cargaHoraria: null,
            codigoUnidadeOrganizacional: null,
            unidadesOrganizacionais: [],

            cpfResponsavel: null,
            codParceiro: null,
            nomeResponsavel: null,
            cpfPesquisado: false,

            orientacaoCliente: null,

            unidades: [],
        };
    },

    validations() {
        return {
            nomeRealizacao: { required },
            codProjeto: { required },
            codAcao: { required },
            codInstrumento: { required },
            tipoRealizacao: { required },
            cargaHoraria: { required },
            codAreaAtendimento: { required },
            codCanalAtendimento: { required },
        };
    },

    methods: {
        obterOpcoes() {
            this.$store.dispatch('addRequest');
            AtendimentoDetalheServices.obterOpcoes().then((response) => {
                if (response?.success) {
                    this.unidadesOrganizacionais = response.data.unidadesOrganizacionais;
                    this.projetos = response.data.projetos;
                    this.acoes = response.data.acoes;
                    this.instrumentos = response.data.instrumentos;
                    this.unidades = response.data.unidades;
                    this.temas = response.data.temas;
                    this.canaisAtendimentos = response.data.canaisAtendimento;
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Opções do Atendimento',
                        detail: 'Falha ao obter opções de seleção',
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },

        preencher() {
            this.codInstrumento = this.atendimentoDetalhe?.codInstrumento;
            this.instrumento = this.atendimentoDetalhe?.instrumento;
            this.tipoRealizacao = this.atendimentoDetalhe?.tipoRealizacao;
            this.nomeRealizacao = this.atendimentoDetalhe?.nomeRealizacao;
            this.cargaHoraria = this.atendimentoDetalhe?.cargaHoraria;
            this.codigoUnidadeOrganizacional = this.atendimentoDetalhe?.codUnidadeOrganizacional;
            this.unidadeOrganizacional = this.atendimentoDetalhe?.unidadeOrganizacional;
            this.codAcao = this.atendimentoDetalhe?.codAcao;
            this.codAcaoSeq = this.atendimentoDetalhe?.codAcaoSeq;
            this.acao = this.atendimentoDetalhe?.acao;
            this.codProjeto = this.atendimentoDetalhe?.codProjeto;
            this.projeto = this.atendimentoDetalhe?.projeto;
            this.cpfResponsavel = this.atendimentoDetalhe?.cpfResponsavel;
            this.codParceiro = this.atendimentoDetalhe?.codParceiro;
            this.responsavel = this.atendimentoDetalhe?.responsavel;
            this.listaEmailsIntegracao = this.atendimentoDetalhe?.listaEmailsIntegracao;
            this.cpfPesquisado = true;
            this.codAreaAtendimento = this.atendimentoDetalhe?.codTema;
            this.codCanalAtendimento = this.atendimentoDetalhe?.codMeioAtendimento;
            this.orientacaoCliente = this.atendimentoDetalhe?.orientacaoCliente;
            this.v$.$touch();
        },

        confirmarSalvar() {
            this.$confirm.require({
                message: `Tem certeza que deseja ${this.operacao.toLowerCase()} as configurações do atendimento?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.salvar();
                },
            });
        },

        salvar() {
            let instrumento = this.instrumentos.filter((item) => {
                return item.codCategoria === this.codInstrumento;
            })[0];

            let projeto = this.projetos.filter((item) => {
                return item.codPratif === this.codProjeto;
            })[0];

            let acao = this.acoes.filter((item) => {
                return item.codAcao === this.codAcao;
            })[0];

            let unidadeOrganizacional = this.unidadesOrganizacionais.filter((item) => {
                return item.codigoUnidadeOrganizacional === this.codigoUnidadeOrganizacional;
            })[0];

            let tema = this.temas.filter((item) => {
                return item.codAreaTematica === this.codAreaAtendimento;
            })[0];

            let canalAtendimento = this.canaisAtendimentos.filter((item) => {
                return item.codMeioAtendimento === this.codCanalAtendimento;
            })[0];

            let atendimentoDetalhe = {
                atendimentoDetalheId: this.atendimentoDetalhe?.atendimentoDetalheId,
                eventoid: this.$route.params.id,
                codInstrumento: this.codInstrumento,
                instrumento: instrumento.descCategoria,
                tipoRealizacao: this.tipoRealizacao,
                nomeRealizacao: this.nomeRealizacao,
                cargaHoraria: this.cargaHoraria,
                codUnidadeOrganizacional: this.codigoUnidadeOrganizacional ? this.codigoUnidadeOrganizacional : 0,
                unidadeOrganizacional: unidadeOrganizacional ? unidadeOrganizacional.descricao : '',
                codAcao: this.codAcao,
                codAcaoSeq: acao.codAcaoSeq,
                acao: acao.nomeAcao,
                codProjeto: this.codProjeto,
                projeto: projeto.nomePratif,
                cpfResponsavel: this.cpfResponsavel ? this.cpfResponsavel : '',
                codParceiro: this.codParceiro ? this.codParceiro : 0,
                responsavel: this.responsavel ? this.responsavel : '',
                codAreaAtendimento: this.codAreaAtendimento,
                codCanalAtendimento: this.codCanalAtendimento,
                tema: tema.temaSuperiorAreaTematica,
                canalAtendimento: canalAtendimento.descMeioAtendimento,
                orientacaoCliente: this.orientacaoCliente,
            };
            this.$emit('salvar', atendimentoDetalhe);
        },

        atualizar() {
            this.$emit('atualizar');
        },

        pesquisarCpf() {
            this.$store.dispatch('addRequest');
            AtendimentoDetalheServices.obterParceiroPorCpf(this.cpfResponsavel).then((response) => {
                if (response?.success) {
                    this.codParceiro = response.data.codParceiro;
                    this.responsavel = response.data.nomeRazaoSocial;
                    this.cpfPesquisado = true;
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Unidade de Atendimento',
                        detail: 'Falha ao obter o parceiro',
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },

        informarNovoCpf() {
            this.cpfResponsavel = null;
            this.codParceiro = null;
            this.responsavel = null;
            this.cpfPesquisado = false;
        },
    },

    mounted() {
        this.obterOpcoes();
    },

    computed: {
        acoesProjeto() {
            return this.acoes.filter((item) => {
                return item.codPratif === this.codProjeto;
            });
        },
    },

    watch: {
        atendimentoDetalhe() {
            if (this.atendimentoDetalhe) this.preencher();
        },

        codProjeto() {
            if (this.operacao == 'INSERIR' || (this.operacao == 'ATUALIZAR' && !this.acaoInicial)) {
                this.codAcao = null;
            }
            if (this.operacao == 'ATUALIZAR' && this.acaoInicial) {
                this.acaoInicial = false;
            }
        },
    },
};
</script>
