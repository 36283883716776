<template>
    <atendimento-detalhe-form
        operacao="ATUALIZAR"
        :cancelar="cancelar"
        :erros="erros"
        :atendimentoDetalhe="atendimentoDetalhe"
        @salvar="atualizar($event)"
        @atualizar="obterAtendimentoDetalhe()"
    ></atendimento-detalhe-form>
</template>

<script>
import AtendimentoDetalheServices from './services';
import AtendimentoDetalheForm from './AtendimentoDetalheForm';

export default {
    components: {
        AtendimentoDetalheForm,
    },

    data() {
        return {
            erros: [],
            atendimentoDetalhe: null,
            atualizado: false,
        };
    },

    methods: {
        cancelar() {
            if (this.atualizado) {
                this.$store.dispatch('setAtualizar', true);
            }
            this.$router.push({
                name: 'Checkins_Eventos_Detalhar',
                params: {
                    id: this.$route.params.id,
                },
                query: {
                    view: 'atendimento-detalhe',
                },
            });
        },

        obterAtendimentoDetalhe() {
            this.$store.dispatch('addRequest');
            AtendimentoDetalheServices.obterAtendimentoDetalhePorEventoId(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.atendimentoDetalhe = response.data;
                } else {
                    this.atendimentoDetalhe = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        atualizar(atendimentoDetalhe) {
            this.$store.dispatch('addRequest');
            AtendimentoDetalheServices.atualizarAtendimentoDetalhe(atendimentoDetalhe, this.atendimentoDetalhe.eventoId).then((response) => {
                if (response?.success) {
                    this.atualizado = true;
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Atendimento Detalhe',
                        detail: 'Configurações de Atendimento inseridas com sucesso',
                        life: 3000,
                    });
                    this.cancelar();
                } else {
                    this.erros = response.errors;
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },

    mounted() {
        this.obterAtendimentoDetalhe();
    },
};
</script>
